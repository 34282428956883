<template>
  <BaseInput
    :value="values[field.id]"
    :mask="{
      ...(field.properties.basic.currencySymbol || {}),
      ...(field.properties.basic.format || {})
    }"
    :type="field.properties.basic.currencySymbol || field.properties.basic.format ? 'text' : 'number'"
    size="is-small"
    :disabled="field.properties.basic.isDisabled"
    :controls="field.properties.basic.showControls"
    :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
    @input="$emit('update', {
      value: $event
    })"
  />
</template>

<script >
import BaseInput from '@/components/generics/BaseInput.vue';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  return {};
};
__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
